import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import ProjectCarousel from "../components/project-gallery/GalleryCarousel";
import Breadcrumbs from '../components/Breadcrumbs'
import useSiteMetadata from "../components/SiteMetadata";
const Star = (
  <svg
    className="w-4 h-4"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="#F68B1F"
  >
    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
  </svg>
);

const ProjectPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  const {
    companyname,
  } = useSiteMetadata();

  return (
    <Layout className="bg-gray-50" bottomBorder={true}>
      <SEO
        title={`${frontmatter.title} in ${frontmatter.city}, ${frontmatter.zip}`}
        keywords={
          frontmatter.metakeywords && frontmatter.metakeywords.join(", ")
        }
        description={frontmatter.metadescription}
        img={
          frontmatter &&
          frontmatter.featuredimage &&
          frontmatter.featuredimage.childImageSharp.fixed.src
        }
      />
      <div className="bg-white border-b border-gray-100">
        <nav className="flex container mx-auto px-4 py-3" aria-label="Breadcrumb">

          <Breadcrumbs links={[{ to: '/', label: 'Home' }, { to: '/projects', label: 'Projects' }, { to: `/project/${frontmatter.slug}`, label: `${frontmatter.title}` }]} />

        </nav>
      </div>


      <div className="container px-4 md:px-6 mx-auto py-4">


      </div>
      <div className="container flex flex-wrap px-4 md:px-6 mx-auto">
        <div className="w-full md:w-1/3 md:pr-8 relative">

          <h1 className="text-xl md:text-2xl font-bold mb-1">{frontmatter.title} in {frontmatter.city}, {frontmatter.zip}</h1>
          <p className="text-base text-gray-500">{frontmatter.description}</p>
          <div className=" bg-white p-6 max-w-lg my-4 rounded-xl">
            <span className="font-semibold text-gray-900 font-display block mb-4">Customer Review</span>
            <div className="flex items-top">
              <svg
                width="30"
                height="30"
                viewBox="0 0 27 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.5 27C20.9558 27 27 20.9558 27 13.5C27 6.04416 20.9558 0 13.5 0C6.04416 0 0 6.04416 0 13.5C0 20.9558 6.04416 27 13.5 27Z"
                  fill="#AAB7C4"
                  fill-opacity="0.2"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M13.4999 27C11.6671 27.0007 9.85357 26.6268 8.1705 25.9013C6.48743 25.1759 4.97036 24.1141 3.7124 22.7812C5.14745 20.1487 8.1755 18.3775 13.4999 18.3775C18.8243 18.3775 21.8537 20.1487 23.2874 22.7812C22.0294 24.1141 20.5123 25.1758 18.8293 25.9012C17.1462 26.6267 15.3327 27.0006 13.4999 27ZM13.5053 5.08815L13.5067 5.08545C16.3106 5.08545 18.5813 7.1199 18.5813 10.4274C18.5813 13.7349 16.3093 15.7761 13.5053 15.7761C10.7027 15.7761 8.4293 13.7403 8.4293 10.4328C8.4293 7.1253 10.7041 5.0868 13.5053 5.0868V5.08815Z"
                  fill="#AAB7C4"
                />
              </svg>
              <div className="ml-2">
                <span className="block font-semibold text-black font-display">
                  Ken G.
                </span>
                <div className="flex items-center">
                  {Star}
                  {Star}
                  {Star}
                  {Star}
                  {Star}
                </div>
              </div>
            </div>
            <span className="text-sm font-display text-gray-500 mt-1 block">
              Bay Area Siding was easy to work with and did a great job on our
              siding and rain gutters. They worked with us to make our house
              look like new.
            </span>
          </div>

          <div className=" bg-white p-6 max-w-lg my-4 rounded-xl hidden md:block">
            <span className="font-semibold text-gray-900 font-display block mb-1">Have a similar project in mind?</span>
            <span className="block text-gray-500 text-sm">{companyname} is proud to provide free in-home estimates.</span>
            <Link
              to={'/contact'}
              title={'Request Free Estimate'}

              className="button-active-bhvr inline-block font-bold tracking-tight text-base cursor-pointer transition duration-150 ease-in-out bg-brand-600 focus:bg-brand-700 hover:bg-brand-700 focus:outline-none px-5 py-3   mt-3 lg:px-8 rounded-full text-white font-display"
            >
              Get Started
              </Link>


          </div>

          {/* <div className="bg-white rounded-xl p-8 pb-6 border-brand-500 md-mb-none shadow-xl relative">

<div className="mb-4">
      <h3 className="font-display leading-6 md:leading-7 font-bold text-2xl text-gray-800">
      Start Your Project
      </h3>
      <p className="text-gray-600 mb-2 leading-6 mt-1">
        Request Free Pricing Today for Siding Replacement Project!
      </p>
    </div>
    <ContactForm hideLabels={true} showPlaceholders={true} footerContained={true}/>
  </div> */}


        </div>
        <div className="w-full md:w-2/3">

          <div className="bg-white rounded-xl max-w-4xl mx-auto mb-3 p-2 relative">
            <ProjectCarousel slides={frontmatter.images} />
          </div>


          <div className=" bg-white p-6 max-w-lg my-4 rounded-xl block md:hidden">
            <span className="font-semibold text-gray-900 font-display block mb-1">Have a similar project in mind?</span>
            <span className="block text-gray-500 text-sm">Bay Area Siding is proud to provide free in-home estimates.</span>
            <Link
              to={'/contact'}
              title={'Request Free Estimate'}

              className="button-active-bhvr inline-block font-bold tracking-tight text-base cursor-pointer transition duration-150 ease-in-out bg-brand-600 focus:bg-brand-700 hover:bg-brand-700 focus:outline-none px-5 py-3   mt-3 lg:px-8 rounded-full text-white font-display"
            >
              Get Started
              </Link>
          </div>

        </div>




      </div>
    </Layout>
  );
};

export default ProjectPage;

export const ProjectPageQuery = graphql`
  query ProjectPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title 
        description 
        city
        slug
        zip
        featuredimage {
          childImageSharp {
            fluid(maxWidth: 1366, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
            fixed(width: 1200, height: 630) {
              width
              height
              src
              srcSet
              srcWebp
            }
          }
        }
        images {
          imgtitle
          image {
            childImageSharp {
              fluid(maxWidth: 1366, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
              fixed(width: 1200, height: 630) {
                width
                height
                src
                srcSet
                srcWebp
              }
            }
          }
        }
        maincontentblocks {
          type
          label
          title
          image {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          value
          description
          contentwithsidebarsidebar {
            sidebar {
              frontmatter {
                title
                bodycontent
                dynamicsidebarid
                renderashtml
                outercontainercss
                id
              }
            }
            sidebarcollection {
              frontmatter {
                title
                sidebars {
                  sidebar {
                    frontmatter {
                      title
                      bodycontent
                      dynamicsidebarid
                      renderashtml
                      outercontainercss
                      id
                    }
                  }
                }
              }
            }
          }
          bodycontent
          photogallery {
            frontmatter {
              title
              description
              images {
                imgtitle
                image {
                  childImageSharp {
                    fluid(maxWidth: 900, quality: 100) {
                      src
                      srcSet
                      srcWebp
                    }
                    fixed(width: 135, height: 118, quality: 100) {
                      width
                      height
                      src
                      srcSet
                      srcWebp
                    }
                  }
                }
              }
            }
          }

          footerlogoshowcase {
            frontmatter {
              title
              images {
                imgtitle
                image {
                  publicURL
                  extension
                  childImageSharp {
                    fluid(maxHeight: 250, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
