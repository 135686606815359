import React, { useState, useEffect, useCallback } from "react";
import { useEmblaCarousel } from "embla-carousel/react";
import { GalleryThumb } from "./GalleryThumb";
import { DotButton, PrevButton, NextButton } from "./CarouselButtons";


const GalleryCarousel = ({ slides }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(true);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(true);
  const [mainViewportRef, embla] = useEmblaCarousel(); 
  const [thumbViewportRef, emblaThumbs] = useEmblaCarousel({
    containScroll: "keepSnaps",
    selectedClass: ""
  });

  const onThumbClick = useCallback(
    (index) => {
      if (!embla || !emblaThumbs) return;
      if (emblaThumbs.clickAllowed()) embla.scrollTo(index);
    },
    [embla, emblaThumbs]
  );

  const onSelect = useCallback(() => {
    if (!embla || !emblaThumbs) return;
    setSelectedIndex(embla.selectedScrollSnap());
    emblaThumbs.scrollTo(embla.selectedScrollSnap());
  }, [embla, emblaThumbs, setSelectedIndex]);

  useEffect(() => {
    if (!embla) return;
    onSelect();
    embla.on("select", onSelect);
  }, [embla, onSelect]);

  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);


  return (
    <>
      <div className="embla">
        <div className="embla__viewport" ref={mainViewportRef}>
          <div className="embla__container">
            {slides.map((item, i) => (
              <div className="embla__slide" key={i}>
                <div className="embla__slide__inner"  style={{minHeight:'420px'}}>
                  <img
                    className="embla__slide__img"
                    src={item.image.childImageSharp.fluid.src}
                    alt={item.imgtitle}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
        <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />

      <div className="embla embla--thumb px-12 md:px-2">
        <div className="embla__viewport" ref={thumbViewportRef}>
          <div className="embla__container embla__container--thumb">
            {slides.map((item,i) => (
              <GalleryThumb
                onClick={() => onThumbClick(i)}
                selected={i === selectedIndex}
                imgSrc={item.image.childImageSharp.fixed.src}
                key={i}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default GalleryCarousel;
